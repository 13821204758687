/**
 * changed by diwuyanting on 2019/04/28.
 */
var _layout = {
  showQrCode(e) {
    const $box = $(this).find('.qr-code-box'),
      type = e.type;

    if (type == 'mouseenter') {
      $box.fadeIn();
    } else {
      $box.hide();
    }
  },

  openLayer() {
    layer.open({
      title: '提示',
      content: '该账号涉嫌违规存在异常行为，</br> 已被封号处理！如有疑问请联系 </br>客服电话：4006790966',
      area: ['395px', '200px'],
      btnAlign: 'c',
      closeBtn: 0,
      skin: "demo-class",
      yes: function (index) {
        _layout.logout();
        layer.close(index);
      }
    });
  },
  getUserInfo() {
    $.get("/user/getUserInfo", { _kp_sso_sign: KEPU_SSO_DATA.sign, _kp_sso_token: KEPU_SSO_DATA.token },
      function (res) {
        if (res.code === 100010006) {
          _layout.openLayer();
        }
        if (res.code == 0 && res.data.kepu_number == 0) {
          $(".hide_kpu_info").hide();
        }
        if (res.code == 0) {
          // if (res.data.avatar) {
          //   $('._userAvatar').attr('src', res.data.avatar);
          // }
          // if (res.data.name) {
          //   $('.menu-user-nikename').html(res.data.name);
          // }
          $('.myAgree').attr('data-is-gf', res.data.is_gf);
          $('._kepuhaoSet').attr('data-is-gf', res.data.is_gf);
          $('.certHome').attr('data-is-gf', res.data.is_gf);
        }
      }
    );
  },
  logout() {
    const logoutUrl = $('#hid').val(),
      controller = $('#control').val(),
      index = $('#index').val(),
      url = window.location.pathname,
      control = url.substr(1, url.lastIndexOf('/') - 1);

    $.ajax({
      type: "get",
      url: logoutUrl,
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: (res) => {

        $.ajax({
          type: "get",
          url: "/user/logout",
          dataType: 'json',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (data) => {

            if (data.code != 0) {
              return false;
            }
            // 清空补充信息记录
            localStorage.setItem("supply_kph_info_dialog_date", "");
            if (controller.indexOf(control) > -1) {
              window.location.href = index;
              return false;
            } else {
              window.location.reload();
              return false;
            }
          }
        });
      }
    });
  },
  showBlue: function () {
    $(this).parent().find('i').addClass('blue-triangle');
  },
  hideBlue: function () {
    $(this).parent().find('i').removeClass('blue-triangle');
  }
};

$(function () {
  _layout.getUserInfo();

  $(document)
    .on('click', '._logout', _layout.logout)
    .on('mouseenter mouseleave', '._qrCode', _layout.showQrCode)
    // hover个人中心 上面三角变蓝
    .on('mouseenter', '.my-center', _layout.showBlue)
    .on('mouseleave', '.my-center', _layout.hideBlue);

});